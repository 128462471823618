// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-books-jsx": () => import("./../../../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-concentrationgames-jsx": () => import("./../../../src/pages/concentrationgames.jsx" /* webpackChunkName: "component---src-pages-concentrationgames-jsx" */),
  "component---src-pages-dev-jsx": () => import("./../../../src/pages/dev.jsx" /* webpackChunkName: "component---src-pages-dev-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-pathpages-jsx": () => import("./../../../src/pages/pathpages.jsx" /* webpackChunkName: "component---src-pages-pathpages-jsx" */),
  "component---src-pages-practicepages-jsx": () => import("./../../../src/pages/practicepages.jsx" /* webpackChunkName: "component---src-pages-practicepages-jsx" */),
  "component---src-pages-speedgrids-jsx": () => import("./../../../src/pages/speedgrids.jsx" /* webpackChunkName: "component---src-pages-speedgrids-jsx" */),
  "component---src-pages-wordfamiles-jsx": () => import("./../../../src/pages/wordfamiles.jsx" /* webpackChunkName: "component---src-pages-wordfamiles-jsx" */),
  "component---src-templates-book-jsx": () => import("./../../../src/templates/book.jsx" /* webpackChunkName: "component---src-templates-book-jsx" */),
  "component---src-templates-concentrationgame-jsx": () => import("./../../../src/templates/concentrationgame.jsx" /* webpackChunkName: "component---src-templates-concentrationgame-jsx" */),
  "component---src-templates-pathpage-jsx": () => import("./../../../src/templates/pathpage.jsx" /* webpackChunkName: "component---src-templates-pathpage-jsx" */),
  "component---src-templates-practicepage-jsx": () => import("./../../../src/templates/practicepage.jsx" /* webpackChunkName: "component---src-templates-practicepage-jsx" */),
  "component---src-templates-speedgrid-jsx": () => import("./../../../src/templates/speedgrid.jsx" /* webpackChunkName: "component---src-templates-speedgrid-jsx" */),
  "component---src-templates-wordfamily-jsx": () => import("./../../../src/templates/wordfamily.jsx" /* webpackChunkName: "component---src-templates-wordfamily-jsx" */)
}

