import React, { createContext, useContext, useReducer } from "react"
import axios from "axios"

const apiURL = "https://cms.hounddogreading.com"

const DEFAULT_STATE = {
  jwt: null,
  user: {},
  loggedIn: false,
}

const isBrowser = () => typeof window !== "undefined"

const getSession = () =>
  isBrowser() && window.localStorage.getItem("hounddogUser")
    ? JSON.parse(window.localStorage.getItem("hounddogUser"))
    : DEFAULT_STATE

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      const { jwt, user } = action.payload
      window.localStorage.setItem(
        "hounddogUser",
        JSON.stringify({ ...state, jwt, user, loggedIn: true })
      )
      return { ...state, jwt, user, loggedIn: true }
    case "LOGOUT":
      window.localStorage.setItem("hounddogUser", JSON.stringify(DEFAULT_STATE))
      return DEFAULT_STATE
    case "UPDATE":
      window.localStorage.setItem("hounddogUser", JSON.stringify(state))
      return {
        ...state,
        jwt: state.jwt,
        user: action.response.data,
        loggedIn: state.loggedIn,
      }
    default:
      return DEFAULT_STATE
  }
}

const AuthContext = createContext()

const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE, getSession)}>
    {children}
  </AuthContext.Provider>
)

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)

const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext)
  const isAuthenticated = state.loggedIn && Object.keys(state.user).length

  const login = (credentials) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local`,
          credentials
        )
        dispatcher({ type: "LOGIN", payload })
        resolve(payload)
      } catch (e) {
        reject(e)
      }
    })
  const logout = () => {
    dispatcher({ type: "LOGOUT" })
  }
  const update = (updatedProgress) => {
    axios
      .put(
        `${apiURL}/users/${state.user.id}`,
        { progress: updatedProgress },
        {
          headers: {
            Authorization: `Bearer ${state.jwt}`,
          },
        }
      )
      .then((response) => {
        dispatcher({ type: "UPDATE", response })
      })
      .catch((e) => {
        console.log("Error: ", e.response)
      })
  }
  const refresh = () => {
    axios
      .get(`${apiURL}/users/me`, {
        headers: {
          Authorization: `Bearer ${state.jwt}`,
        },
      })
      .then((response) => {
        dispatcher({ type: "UPDATE", response })
      })
      .catch((e) => {
        console.log("Error: ", e.response)
      })
  }
  return { state, isAuthenticated, login, logout, update, refresh }
}

export default useAuth
